import React from 'react';
import Swiper from 'react-id-swiper';
import classList from 'react-classlist-helper';
import './PhotoGrid.scss';
import 'swiper/swiper.scss';

export default (props) => {
  const params = {
    slidesPerView: 1,
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
    },
    initialSlide: props.startIndex || 0,
    breakpoints: !props.lightbox ? {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
        spaceBetween: 0
      },
      // when window width is >= 640px
      1600: {
        slidesPerView: 3,
        spaceBetween: 0
      }
    } : {},
    pagination: props.photos.length > 1 ? {
      el: '.photogrid__pagination',
      type: 'bullets',
      clickable: true
    }:{},
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 0
  }

  const classMap = {
    "photogrid": true,
    "photogrid--lightbox": !!props.lightbox,
    [props.className]: !!props.className,
  };

  const renderImage = (photo, index) => {
    return (
      <button aria-label="Click to enlarge" key={index} type="button" className="photogrid__photo swiper-lazy-preloader" onClick={() => props.onPhotoClick({ title: props.title, index })}>
        <div className="photogrid__photo-overlay">
          <span>{photo.title}</span>
        </div>
        <img className="photogrid__photo-img swiper-lazy" data-src={photo.url} alt={photo.title} />
      </button>
    )
  }

  const renderVideo = (photo, index) => {
    return (
      <iframe title={photo.title} key={index} src={photo.url} />  
    )
  }

  return (
    <div id={props.title} className={classList(classMap)}>
      <h2 className="h2 photogrid__title">{props.title}</h2>
      { props.description &&
        <p className="photogrid__desc">
          {props.description}
        </p>
      }
      <Swiper {...params} className="photogrid__grid">
        { props.photos.map((photo, index) => {
          return photo.type === 'photo' ? (
            renderImage(photo, index)
          ) : (
            renderVideo(photo, index)
          )
        })
        }
      </Swiper>
    </div>
  );
}