import React from 'react';
import classList from 'react-classlist-helper';
import './Cta.scss';

export default (props) => {
  const classMap = {
    "cta": true,
    [props.className]: !!props.className,
  };

  if (props.href) {
    return (
      <a href={props.href} className={classList(classMap)}>
        {props.children}
      </a>
    )
  } else {
    return (
      <button 
        type="button" 
        className={classList(classMap)}
        onClick={props.onClick}
      >
        {props.children}
      </button>
    )
  }
}