import React from 'react';
import GoogleMapReact from 'google-map-react';
import MapStyle from './MapStyle.json';
import './Map.scss';

const Marker = () => <div className="marker"></div>;

export default (props) => {
  return (
    <div className="map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyA0ARr9eMn8t-GcpVDCedCF3Dpu6ykm64s' }}
        defaultCenter={props.center}
        defaultZoom={props.zoom}
        options={{
          styles: MapStyle
        }}
      >
        <Marker
          lat={51.515170}
          lng={-0.122820}
        />
      </GoogleMapReact>
    </div>
  );
}
