import React from 'react';
import SocialLinks from '../SocialLinks/SocialLinks';
import './Footer.scss';

export default (props) => {
  return (
    <footer className="footer">
      <div className="footer__wrap">
        <div className="footer__group">
          <h4>Enquiries</h4>
          <ul>
            <li><span>E: </span><a href="mailto:enquiries@cowboykidlondon.com">enquiries@cowboykidlondon.com</a></li>
            <li><span>T: </span><a href="tel:+442031502146">+44 (0)203 150 2146</a></li>
          </ul>
        </div>
        <div className="footer__group">
          <h4>London</h4>
          <a href="https://goo.gl/maps/YyTz4UU2WDjoDTqt9" target="_blank" rel="noopener noreferrer">Elvis<br />172 Drury Lane<br />London<br />WC2B 5QR</a>
        </div>
        <div className="footer__group">
          <h4 className="type-faux--h4">Follow us</h4>
          <SocialLinks />
        </div>
      </div>
      <div className="footer__copyright">
        <ul className="footer__policies">
          <li>&copy; Copyright Elvis Communications Limited 2020</li>
        </ul>
      </div>
    </footer>
  )
}
