import ImageHeadshot1 from '../assets/images/headshots/live-agency_james.jpg';
import ImageHeadshot2 from '../assets/images/headshots/live-agency_radhika.jpg';
import ImageHeadshot3 from '../assets/images/headshots/zebedee_worldcpday_holly.jpg';
import ImageHeadshot4 from '../assets/images/headshots/Zebedee_WorldCPDay_JT.jpg';
import ImageHeadshot5 from '../assets/images/headshots/dotty6.jpg';
import ImageHeadshot6 from '../assets/images/headshots/elvis_adweek_board.jpg';
import ImageHeadshot7 from '../assets/images/headshots/elvis_adweek_caroline.jpg';
import ImageHeadshot9 from '../assets/images/headshots/elvis_adweek_emma.jpg';
import ImageHeadshot10 from '../assets/images/headshots/elvis_adweek_neale.jpg';
import ImageHeadshot11 from '../assets/images/headshots/elvis_adweek_tanya.jpg';
import ImageHeadshot12 from '../assets/images/headshots/group2.jpg';
import ImageHeadshot13 from '../assets/images/headshots/group3.jpg';
import ImageHeadshot14 from '../assets/images/headshots/iyass3.jpg';
import ImageHeadshot15 from '../assets/images/headshots/live-agency_james.jpg';
import ImageHeadshot16 from '../assets/images/headshots/live-agency_kate.jpg';
import ImageHeadshot17 from '../assets/images/headshots/live-agency_lauren.jpg';
import ImageHeadshot18 from '../assets/images/headshots/live-agency_leyton.jpg';
import ImageHeadshot19 from '../assets/images/headshots/live-agency_radhika.jpg';
import ImageHeadshot20 from '../assets/images/headshots/live-agency_sam.jpg';
import ImageHeadshot21 from '../assets/images/headshots/Abbi.jpg';
import ImageHeadshot22 from '../assets/images/headshots/Camilla.jpg';
import ImageHeadshot23 from '../assets/images/headshots/Elli.jpg';
import ImageHeadshot24 from '../assets/images/headshots/Louis.jpg';
import ImageHeadshot25 from '../assets/images/headshots/Niz.jpg';

import ImagePackshot1 from '../assets/images/packshots/bass_imported_pale_ale.jpg';
import ImagePackshot2 from '../assets/images/packshots/bass_imported_pint_glass.jpg';
import ImagePackshot3 from '../assets/images/packshots/bass_premium_pale_ale.jpg';
import ImagePackshot4 from '../assets/images/packshots/INB4162-Corona-Cooler2.jpg';
import ImagePackshot5 from '../assets/images/packshots/Civic1.jpg';
import ImagePackshot6 from '../assets/images/packshots/civic3.jpg';
import ImagePackshot7 from '../assets/images/packshots/DMs_Retouch.jpg';
import ImagePackshot8 from '../assets/images/packshots/DMs_Retouch4.jpg';
import ImagePackshot9 from '../assets/images/packshots/DMs_Retouch5.jpg';
import ImagePackshot10 from '../assets/images/packshots/DMs_Retouch6.jpg';
import ImagePackshot11 from '../assets/images/packshots/DMs_Retouch7.jpg';
import ImagePackshot12 from '../assets/images/packshots/DMs_Retouch8.jpg';
import ImagePackshot13 from '../assets/images/packshots/DMs_Retouch9.jpg';
import ImagePackshot14 from '../assets/images/packshots/INB3473_Bud_Equity_Product_Budweiser_Glasses2_rgb_LR.jpg';
import ImagePackshot15 from '../assets/images/packshots/Range1.jpg';
import ImagePackshot16 from '../assets/images/packshots/Range3.jpg';
import ImagePackshot17 from '../assets/images/packshots/stella-artois-ballad.jpg';
import ImagePackshot18 from '../assets/images/packshots/TypeR1.jpg';

import ImageReportage1 from '../assets/images/reportage/budweiser_fifa_worlcup-boat1.jpg';
import ImageReportage2 from '../assets/images/reportage/budweiser_fifa_worlcup-boat2.jpg';
import ImageReportage3 from '../assets/images/reportage/budweiser_fifa_worlcup-boat4.jpg';
import ImageReportage4 from '../assets/images/reportage/budweiser_fifa_worlcup-boat5.jpg';
import ImageReportage5 from '../assets/images/reportage/Civic1.jpg';
import ImageReportage7 from '../assets/images/reportage/illegal-blood-bank_adshell_1.jpg';
import ImageReportage8 from '../assets/images/reportage/illegal-blood-bank_adshell_2.jpg';
import ImageReportage9 from '../assets/images/reportage/illegal-blood-bank_adshell_3.jpg';
import ImageReportage10 from '../assets/images/reportage/illegal-blood-bank_adshell_4.jpg';
import ImageReportage11 from '../assets/images/reportage/illegal-blood-bank_adshell_5.jpg';
import ImageReportage12 from '../assets/images/reportage/illegal-blood-bank_adshell_6.jpg';
import ImageReportage13 from '../assets/images/reportage/illegal-blood-bank_adshell_7.jpg';
import ImageReportage14 from '../assets/images/reportage/illegal-blood-bank_adshell_8.jpg';
import ImageReportage15 from '../assets/images/reportage/illegal-blood-bank_adshell_9.jpg';
import ImageReportage16 from '../assets/images/reportage/illegal-blood-bank_adshell_10.jpg';
import ImageReportage17 from '../assets/images/reportage/illegal-blood-bank_adshell_11.jpg';
import ImageReportage18 from '../assets/images/reportage/illegal-blood-bank_adshell_12.jpg';
import ImageReportage19 from '../assets/images/reportage/illegal-blood-bank_adshell_13.jpg';
import ImageReportage20 from '../assets/images/reportage/illegal-blood-bank_adshell_14.jpg';
import ImageReportage21 from '../assets/images/reportage/IMG_9438Export.jpg';
import ImageReportage22 from '../assets/images/reportage/IMG_9526Export.jpg';
import ImageReportage23 from '../assets/images/reportage/IMG_9709Export.jpg';
import ImageReportage24 from '../assets/images/reportage/IMG_9881Export.jpg';
import ImageReportage25 from '../assets/images/reportage/IMG_9929Export.jpg';
import ImageReportage26 from '../assets/images/reportage/Range3.jpg';
import ImageReportage27 from '../assets/images/reportage/TypeR1.jpg';

export default {
  Showreel: [
    { title: "", url: 'https://player.vimeo.com/video/397416992?autoplay=1&loop=1&autopause=0', type: 'video', }
  ],
  Headshots: [
    { title: "", url: ImageHeadshot4, type: "photo" },
    { title: "", url: ImageHeadshot2, type: "photo" },
    { title: "", url: ImageHeadshot21, type: "photo" },
    { title: "", url: ImageHeadshot3, type: "photo" },
    { title: "", url: ImageHeadshot1, type: "photo" },
    { title: "", url: ImageHeadshot5, type: "photo" },
    { title: "", url: ImageHeadshot22, type: "photo" },
    { title: "", url: ImageHeadshot6, type: "photo" },
    { title: "", url: ImageHeadshot7, type: "photo" },
    { title: "", url: ImageHeadshot9, type: "photo" },
    { title: "", url: ImageHeadshot10, type: "photo" },
    { title: "", url: ImageHeadshot11, type: "photo" },
    { title: "", url: ImageHeadshot24, type: "photo" },
    { title: "", url: ImageHeadshot12, type: "photo" },
    { title: "", url: ImageHeadshot13, type: "photo" },
    { title: "", url: ImageHeadshot14, type: "photo" },
    { title: "", url: ImageHeadshot25, type: "photo" },
    { title: "", url: ImageHeadshot15, type: "photo" },
    { title: "", url: ImageHeadshot16, type: "photo" },
    { title: "", url: ImageHeadshot17, type: "photo" },
    { title: "", url: ImageHeadshot23, type: "photo" },
    { title: "", url: ImageHeadshot18, type: "photo" },
    { title: "", url: ImageHeadshot19, type: "photo" },
    { title: "", url: ImageHeadshot20, type: "photo" },
  ],
  Packshots: [
    { title: "", url: ImagePackshot15, type: "photo" },
    { title: "", url: ImagePackshot16, type: "photo" },
    { title: "", url: ImagePackshot17, type: "photo" },
    { title: "", url: ImagePackshot1, type: "photo" },
    { title: "", url: ImagePackshot2, type: "photo" },
    { title: "", url: ImagePackshot3, type: "photo" },
    { title: "", url: ImagePackshot4, type: "photo" },
    { title: "", url: ImagePackshot5, type: "photo" },
    { title: "", url: ImagePackshot6, type: "photo" },
    { title: "", url: ImagePackshot7, type: "photo" },
    { title: "", url: ImagePackshot8, type: "photo" },
    { title: "", url: ImagePackshot9, type: "photo" },
    { title: "", url: ImagePackshot10, type: "photo" },
    { title: "", url: ImagePackshot11, type: "photo" },
    { title: "", url: ImagePackshot12, type: "photo" },
    { title: "", url: ImagePackshot13, type: "photo" },
    { title: "", url: ImagePackshot14, type: "photo" },
    { title: "", url: ImagePackshot18, type: "photo" },
  ],
  Reportage: [
    { title: "", url: ImageReportage1, type: "photo" },
    { title: "", url: ImageReportage2, type: "photo" },
    { title: "", url: ImageReportage3, type: "photo" },
    { title: "", url: ImageReportage4, type: "photo" },
    { title: "", url: ImageReportage5, type: "photo" },
    //{ title: "", url: ImageReportage6, type: "photo" },
    { title: "", url: ImageReportage7, type: "photo" },
    { title: "", url: ImageReportage8, type: "photo" },
    { title: "", url: ImageReportage9, type: "photo" },
    { title: "", url: ImageReportage10, type: "photo" },
    { title: "", url: ImageReportage11, type: "photo" },
    { title: "", url: ImageReportage12, type: "photo" },
    { title: "", url: ImageReportage13, type: "photo" },
    { title: "", url: ImageReportage14, type: "photo" },
    { title: "", url: ImageReportage15, type: "photo" },
    { title: "", url: ImageReportage16, type: "photo" },
    { title: "", url: ImageReportage17, type: "photo" },
    { title: "", url: ImageReportage18, type: "photo" },
    { title: "", url: ImageReportage19, type: "photo" },
    { title: "", url: ImageReportage20, type: "photo" },
    { title: "", url: ImageReportage21, type: "photo" },
    { title: "", url: ImageReportage22, type: "photo" },
    { title: "", url: ImageReportage23, type: "photo" },
    { title: "", url: ImageReportage24, type: "photo" },
    { title: "", url: ImageReportage25, type: "photo" },
    { title: "", url: ImageReportage26, type: "photo" },
    { title: "", url: ImageReportage27, type: "photo" },
  ]
}
