import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './Home/Home';
import FourOhFour from './Errors/404';

export default (props) => {
  return (
    <Switch>
      <Route 
        exact 
        key="home"
        path="/">
        <Home />
      </Route>
      <Route>
        <FourOhFour />
      </Route>
    </Switch>
  );
}