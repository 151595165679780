import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Routes from './Routes';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import '../styles/base.scss';

class App extends Component {
  render() {
    return (
      <>
        <Helmet title="Cowboy Kid | The inhouse photography studio at award-winning creative agency ELVIS" />
        <Header />
        <Routes />
        <Footer />
      </>
    );
  }
}

export default App;
