import React, { useState } from 'react';
import Map from '../../components/Map/Map';
import Lightbox from '../../components/Lightbox/Lightbox';
import Cta from '../../components/Cta/Cta';
import PhotoGrid from '../../components/PhotoGrid/PhotoGrid';
import ImageLogo from '../../assets/images/logo.png';
import photos from '../../data/photos';
import './Home.scss';

export default () => {
  const [lightbox, setLightbox] = useState(null);

  const openReel = () => {
    setLightbox({
      title: 'Showreel',
      index: 0
    });
  }
  
  const renderPhotoGrid = (props) => {
    return (
      <PhotoGrid 
        {...props}
        photos={photos[props.title]}
        onPhotoClick={data => setLightbox(data)}
      />
    )
  }

  return (
    <div className="home">
      <div className="layout home__intro">
        <h1 className="h1 home__title">
          <img src={ImageLogo} alt="Cowboy Kid" />
        </h1>
        <Cta className="home__cta" onClick={openReel}>Showreel</Cta>
      </div>
      <div className="home__copy">
        <p className="justify">
          <strong>The inhouse photography studio at award-winning creative agency ELVIS</strong>
        </p>
        <ul className="home__list">
          <li>15 years' professional photography experience</li>
          <li>Best in class team of onsite photographers and retouchers</li>
          <li>Based in London's vibrant Covent Garden</li>
          <li>Partnering all types of clients - from corporate to individuals, brands and talent</li>
        </ul>
      </div>
      <section id="portfolio" className="layout">
        <div className="home__portfolio">
          {
            renderPhotoGrid({ 
              title: "Headshots", 
              description: "We get the professional and emotional importance of getting your headshot just right. In our studio or out at location, our photographers will make you feel at ease, to ensure we capture exactly the right shot for you. Our packages are tailored to our different clients including corporate, individual and celebrity/talent. And, our studio is fully accessible.",
            })
          }
          {
            renderPhotoGrid({ 
              title: "Packshots", 
              description: "Our packshot studio offers everything from simple brand photography to creative campaign assets, delivered efficiently, and most importantly, to brief. We’ve worked on award-winning projects with some of the world’s greatest FMCG brands, including Mondelez, ABInBev, Nomad Foods and PepsiCo.",
            })
          }
          {
            renderPhotoGrid({ 
              title: "Reportage", 
              description: "Our skilled photographers are experts in event reportage. We understand how to create a simple and compelling brand narrative that can authentically capture real moments and experiences – without being intrusive. We cover anything from large events and festivals, to intimate brand experiences.",
            })
          }
        </div>
      </section>
      <section className="layout">
        <div className="home__map">
          <h2 className="h2 home__map-title">Get in touch</h2>
          <p className="home__map-desc">
            Cowboy Kid is based within award winning creative agency ELVIS, in the heart of Covent Garden:
          </p>
        </div>
        <Map 
          center={{
            lat: 51.515170,
            lng: -0.122820
          }}
          zoom={14}
        />
      </section>
      { lightbox &&
        <Lightbox
          onClose={() => setLightbox(null)}
        >
          {
            renderPhotoGrid({
              startIndex: lightbox.index, 
              title: lightbox.title,
            })
          }
        </Lightbox>
      }
    </div>
  );
}