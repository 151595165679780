import React, { useEffect } from 'react';
import FocusTrap from 'focus-trap-react';
import classList from 'react-classlist-helper';
import './Lightbox.scss';

export default (props) => {
  const classMap = {
    "lightbox": true,
    [props.className]: !!props.className,
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    return () => document.removeEventListener("keydown", handleKeydown);
  });

  const handleKeydown = (event) => {
    if (event.keyCode === 27) {
      props.onClose();
    }
  }

  return (
    <FocusTrap>
      <div className={classList(classMap)}>
        <button type="button" className="lightbox__close" onClick={props.onClose}>
          <span></span>
          <span></span>
        </button>
        { React.cloneElement(props.children, { lightbox: true, ...props }) }
      </div>
    </FocusTrap>
  )
}