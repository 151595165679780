import React, { useEffect, useState, useRef } from 'react';
import classList from 'react-classlist-helper';
import ImageFlash from '../../assets/images/flash.png';

import './Header.scss';
import SocialLinks from '../SocialLinks/SocialLinks';

let lastScroll = 0;
let direction = 0;
let menuOpen = false;
let ticking = false;

export default function (props) {
  const [hide, setHide] = useState(false);
  const [bg, setBg] = useState(false);
  const menuStateInput = useRef();

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });

  const onScroll = () => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        handleScroll();
        ticking = false;
      });
      ticking = true;
    }
  }

  const handleScroll = async () => {
    let scroll = getScroll();
    direction = (scroll > lastScroll) ? 1 : -1;

    if (menuStateInput.current) {
      menuOpen = await getMenuState();
    }

    setHide(direction === 1 && !menuOpen && scroll > 150);
    lastScroll = scroll;

    setBg(scroll >= 300);
  }

  const getMenuState = async () => {
    return menuStateInput.current.checked;
  }

  const getScroll = () => {
    return window.scrollY || window.pageYOffset;
  }

  const classMap = {
    "header": true,
    "header--hide": hide,
    "header--bg": bg,
    [props.className]: !!props.className,
  };

  const handleClick = () => {
    menuStateInput.current.click();
  }

	return (
		<header className={classList(classMap)}>
			<div className="header__logo">
        <img src={ImageFlash} alt="" />
			</div>
      <nav className="header__nav">
        <div className="header__menuToggle">
          <input type="checkbox" ref={menuStateInput} />
          <span></span>
          <span></span>
          <span></span>
          <ul className="header__menu">
            <li><a className="header__link" href="/#Headshots" onClick={handleClick}>Headshots</a></li>
            <li><a className="header__link" href="/#Packshots" onClick={handleClick}>Packshots</a></li>
            <li><a className="header__link" href="/#Reportage" onClick={handleClick}>Reportage</a></li>
            <li className="header__social">
              <SocialLinks />
            </li>
          </ul>
        </div>
      </nav>
		</header>
	);
}