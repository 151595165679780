import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { render } from 'react-snapshot';
import App from './containers/App';

render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
