import React from 'react';
import classList from 'react-classlist-helper';
import './SocialLinks.scss';

export default (props) => {
  const classMap = {
    "social": true,
    [props.className]: !!props.className,
  };

  return (
    <ol className={classList(classMap)}>
      <li>
        <a href="https://www.instagram.com/cowboykid_london/" className="nav-menu__item nav-menu__item--icon js-nav-menu-link" target="_blank" rel="noopener noreferrer" aria-label="Visit our Instagram page">
          <svg className="social__instagram" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
            <path className="cls-1"
                  d="M497.1,147.4c-1.2-26.5-5.4-44.6-11.6-60.5a122.1,122.1,0,0,0-28.7-44.1,121.9,121.9,0,0,0-44-28.7C397,8,379,3.8,352.5,2.5S317.5,1.1,250,1.1s-76,.3-102.5,1.5S102.9,8,87.1,14.1a121.9,121.9,0,0,0-44,28.7A122.1,122.1,0,0,0,14.4,87C8.2,102.8,4,120.9,2.8,147.4s-1.5,35-1.5,102.7.3,76.1,1.5,102.7,5.4,44.6,11.6,60.5a122,122,0,0,0,28.7,44.1,121.8,121.8,0,0,0,44,28.7c15.8,6.2,33.9,10.4,60.4,11.6s35,1.5,102.5,1.5,76-.3,102.5-1.5,44.5-5.4,60.4-11.6a127.3,127.3,0,0,0,72.7-72.8c6.1-15.8,10.4-33.9,11.6-60.5s1.5-35,1.5-102.7S498.4,174,497.1,147.4ZM452.4,350.7c-1.1,24.3-5.2,37.5-8.6,46.2a82.4,82.4,0,0,1-47.2,47.3c-8.8,3.4-21.9,7.5-46.2,8.6-26.2,1.2-34.1,1.5-100.5,1.5s-74.3-.3-100.5-1.5c-24.2-1.1-37.4-5.2-46.2-8.6a76.9,76.9,0,0,1-28.6-18.6A77.1,77.1,0,0,1,56.1,397c-3.4-8.8-7.5-22-8.6-46.2-1.2-26.3-1.5-34.1-1.5-100.6s.3-74.4,1.5-100.6c1.1-24.3,5.2-37.5,8.6-46.2A77.1,77.1,0,0,1,74.7,74.6,77,77,0,0,1,103.3,56c8.8-3.4,21.9-7.5,46.2-8.6,26.2-1.2,34.1-1.5,100.5-1.5s74.3.3,100.5,1.5c24.2,1.1,37.4,5.2,46.2,8.6a77,77,0,0,1,28.6,18.6,77.1,77.1,0,0,1,18.6,28.6c3.4,8.8,7.5,22,8.6,46.2,1.2,26.3,1.5,34.1,1.5,100.6S453.6,324.5,452.4,350.7ZM250,122.2c-70.5,0-127.7,57.3-127.7,127.9S179.5,378,250,378s127.7-57.2,127.7-127.9S320.5,122.2,250,122.2Zm0,210.9a83,83,0,1,1,82.9-83A82.9,82.9,0,0,1,250,333.1ZM382.7,87.3a29.9,29.9,0,1,0,29.8,29.9A29.9,29.9,0,0,0,382.7,87.3Z"/>
          </svg>
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/company/cowboy-kid" className="nav-menu__item nav-menu__item--icon js-nav-menu-link" target="_blank" rel="noopener noreferrer" aria-label="Visit our Linkedin page">
          <svg className="social__linkedin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
            <rect x="10" y="167" width="102" height="331"/>
            <path d="M373.9,159.7c-50,0-83.4,27.3-97.2,53.3H275V167H178V498H280V334.4c0-43,8.4-84.7,61.6-84.7S395,298.8,395,337V498H497V316.7C497,227.9,477.8,159.7,373.9,159.7Z"/>
            <path d="M61.5,4a59.4,59.4,0,1,0,59.4,59.4A59.4,59.4,0,0,0,61.5,4Z"/>
          </svg>
        </a>
      </li>
    </ol>
  )
}